<ng-container *ngIf="notification">
    <div class="modal-header">
        <span class="modal-title mt-4 fs-3" id="modal-basic-title">
      Bedankt voor je inschrijving!
    </span>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div class="modal-body pt-3 pb-0" style="font-size: 1.25rem;">
        <p>We hebben het e-mailadres <strong>{{notification.email}}</strong> goed genoteerd en alvast succesvol gekoppeld aan de gemeente <strong>{{postcode?.postcode}} {{postcode?.name}}</strong>.</p>
        <p>Let wel, voor je meldingen van deze gemeente kan ontvangen, moet je <strong>jouw e-mailadres eerst nog bevestigen</strong> via de mail in je mailbox.</p>
        <p>Kan je de mail niet meteen vinden, kijk dan zeker ook je Spam of 'Ongewenste mails' na.</p>
    </div>
    <div class="modal-footer d-block text-center border-top-0 py-4">
        <button type="button" class="btn pxp-section-cta rounded-pill px-3 py-1" aria-label="Close" (click)="dismiss()">Venster sluiten</button>
    </div>
</ng-container>